import React from 'react';
import "./Jurisdiction.css";
import Box from "@mui/material/Box";
import { Document, Province } from '../../index';
import search from "../../../../assets/images/chatbot/search-icon.svg"

const Jurisdiction = () => {

    const provincesData = [
        "Manitoba", "Northwest Territories.", "Nova Scotia.",
        "Manitoba", "Northwest Territories.", "Nova Scotia.",
        "Manitoba", "Northwest Territories.", "Nova Scotia.",
        "Manitoba", "Northwest Territories.", "Nova Scotia.",
        "Manitoba"
    ];

    const documentsData = [
        {
            name: "All Documents",
            active: false

        },
        {
            name: "regulations",
            active: true
        },
        {
            name: "acts",
            active: true
        },
        {
            name: "Reports",
            active: false
        }
    ]

    const selectedDocumentsData = [
        "Ministerial Order", "Designation and Transfer of Responsibility Regulation", "Ministerial Order 29/24"
    ]

    // Function to chunk the provincesData array into groups of 3
    const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const chunkedProvinces = chunkArray(provincesData, 3);

    return (
        <Box className="jurisdiction-modal">
            <h1 className='jurisdiction-modal-title'>
                Select Jurisdiction<span>Current Docs Selected (30)</span>
            </h1>
            <h1 className='jurisdiction-modal-provinces-title'>Provinces and Territories</h1>
            <div className='jurisdiction-modal-provinces-container'>
                {
                    chunkedProvinces.map((chunk, index) => (
                        <div key={index} className="province-group">
                            {
                                chunk.map((item, idx) => (
                                    <Province key={idx} label={item} />
                                ))
                            }
                        </div>
                    ))
                }
            </div>
            <div className='jurisdiction-modal-documents'>
                {
                    documentsData.map((item, index) => (
                        <Document
                            key={index}
                            active={item.active}
                            name={item.name}
                        />
                    ))
                }
            </div>

            <div className='jurisdiction-modal-search'>
                <input 
                    type='search'
                    className='jurisdiction-modal-search-input'
                    placeholder='Search about all documents, regulations, acts, Reports as you need' 
                />
                <img src={search} className='jurisdiction-modal-search-icon' />
            </div>

            <div className='jurisdiction-modal-selected-documents'>
                {
                    selectedDocumentsData.map((item, index) => (
                        <Document
                            key={index}
                            name={item}
                            close={true}
                        />
                    ))
                }

            </div>
               
            <div className='jurisdiction-modal-provinces-footer'>
                <button className='jurisdiction-modal-provinces-footer-btn'>Clear & save</button>
                <button className='jurisdiction-modal-provinces-footer-btn'>Apply Filters</button>
            </div>
        </Box>
    );
};

export default Jurisdiction;
