import React, { useState, useEffect } from "react";
import "./Reminders.css";
import { Title, Text, Note, DatePickers } from "../../../../Components";
import notifictaion from "../../../../assets/images/svg/balcknotification.svg";
import arrowRight from "../../../../assets/images/svg/arrowRight.svg";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap"; // Assuming you are using React-Bootstrap
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

import Box from '@mui/material/Box';
import SubModal from '@mui/material/Modal';

const Reminders = ({ url, data , translations, language}) => {
  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [fullDescription, setFullDescription] = useState("");
  const [isClear, setIsClear] = useState(false);

  const [subPopup, setSubPopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState("")   

    const handleClose = () => {
        setSubPopup(false);
    };


  const dashboardSection = data
    ? data?.find((item) => item.section === "Dashboard")
    : null;

  const formatDateStyle = (dateString) => {
    const date = new Date(dateString);
    const timeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return timeString !== "00:00"
      ? date.toLocaleString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        })
      : date.toLocaleDateString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
  };

  const handleResetDate = () => {
    setSelectedDate(null); // Reset the selected date
    setIsClear(true);
  };

  const [selectedDate, setSelectedDate] = useState({
    startDate: null,
    endDate: null,
  });

  const handleDateChange = (date) => {
    if (!date || !date.startDate || !date.endDate) {
      // Handle the case where date or startDate or endDate is null
      return;
    }

    // Function to format date as "Y-%m-%d %H:%M:%S"
    const formatDate = (inputDate) => {
      if (!inputDate) return null;
      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
      const day = inputDate.getDate().toString().padStart(2, "0");
      const hours = inputDate.getHours().toString().padStart(2, "0");
      const minutes = inputDate.getMinutes().toString().padStart(2, "0");
      const seconds = inputDate.getSeconds().toString().padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    // Set time to midnight for the start date and format it
    const startDateTime =
      date && date.startDate
        ? formatDate(new Date(date.startDate.setHours(0, 0, 0, 0)))
        : null;
    // Set time to midnight for the end date and format it
    const endDateTime =
      date && date.endDate
        ? formatDate(new Date(date.endDate.setHours(23, 59, 59, 0)))
        : null;
    setSelectedDate({
      startDate: startDateTime,
      endDate: endDateTime,
    });
  };

  useEffect(() => {
    if (
      (selectedDate &&
        (selectedDate.startDate !== null || selectedDate.endDate !== null)) ||
      (isClear && isClear === true)
    ) {
      fetchData();
    }
  }, [selectedDate, isClear]);

  const fetchData = async () => {
    try {
      let apiUrl = `${url}/api/method/frappe.automation.doctype.reminder.reminder.reminder`;
      if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
        apiUrl += `?remind_at_start=${selectedDate.startDate}&remind_at_end=${selectedDate.endDate}`;
      }
      setLoading(true); // Start loading
      const apiKey = Cookies.get("api_key");
      const apiSecret = Cookies.get("api_secret");
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      });

      if (response.data.status_code !== 200) {
        toast.error(translations?.message?.failedFetchData ? translations.message.failedFetchData :"Failed to fetch data. Please try again..");
      }

      if (response?.data?.status_code === 202) {
        setSubPopup(true);
      }

      const result = response.data.result;
      setReminders(result?.objects?.slice(0, 3) ?? []);
      setLoading(false); // End loading
    } catch (error) {
      if (error?.response?.data?.status_code === 202) {
        setSubPopup(true);
        setMessagePopup(error?.response?.data?.message)
      }
      toast.error(translations?.message?.failedFetchData ? translations.message.failedFetchData :"Failed to fetch data. Please try again..");
      console.error("Error fetching data:", error);
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    if (
      dashboardSection &&
      dashboardSection?.data &&
      dashboardSection?.data?.content &&
      dashboardSection?.data?.content?.reminders &&
      dashboardSection?.data?.content?.reminders?.objects
    ) {
      const reminderObjects =
        dashboardSection?.data?.content?.reminders?.objects.map((item) => {
          return {
            ...item,
          };
        });

      setReminders(reminderObjects);
      setLoading(false);
    }
  }, [dashboardSection]);

  const handleReadMore = (description) => {
    setFullDescription(description);
    setShowModal(true);
  };

  const DescriptionWithReadMore = ({ description }) => {
    const maxLength = 100; // Max description length before showing "Read More"
    if (description.length > maxLength) {
      return (
        <>
          {description.substring(0, maxLength)}...
          <a onClick={() => handleReadMore(description)} className="read-more">
          {translations?.message?.readMore ? translations.message.readMore : "Read More"}
          </a>
        </>
      );
    } else {
      return <>{description}</>;
    }
  };

  return (
    <div className="reminders">
      <Title
        icon={notifictaion}
        title={translations?.message?.reminders ? translations.message.reminders : "Reminders"} 
        showInfo={true}
        titleBtn={true}
        onDateChange={handleDateChange}
        onReset={handleResetDate}
        tooltip={translations?.message?.remindersTooltip ? translations.message.remindersTooltip : `"Reminders" provide information on upcoming deadlines that are included in legislation (ie.,Regulations will be coming into force, dates that are specified in legislation).`} 
        translations={translations}
        language={language}
     />
      <div className="mt-3 hide-responsive">
        <Text para={translations?.message?.byDateRange ? translations.message.byDateRange : "By data range:"}/>
      </div>
      <div className="mt-2 hide-responsive">
        <DatePickers
          inputType={"datetime"}
          onDateChange={handleDateChange}
          onReset={handleResetDate}
          translations={translations}
          language={language}
        />
      </div>
      {/* Date picker and other UI components */}

      <div className="note-container">
        {loading ? (
          <>
            <div className="reminder-skelton">
              <div className="first-reminder-skelton">
                <Skeleton width={"100%"} height={18} />
                <Skeleton width={"100%"} height={13} />
              </div>
              <div className="first-reminder-skelton">
                <Skeleton width={"100%"} height={15} />
                <Skeleton width={"90%"} height={13} />
                <Skeleton width={"103%"} height={15} />
                <Skeleton width={"90%"} height={13} />
              </div>
            </div>
            <br />
            <div className="reminder-skelton">
              <div className="first-reminder-skelton">
                <Skeleton width={"100%"} height={18} />
                <Skeleton width={"100%"} height={13} />
              </div>
              <div className="first-reminder-skelton">
                <Skeleton width={"100%"} height={15} />
                <Skeleton width={"95%"} height={13} />
                <Skeleton width={"103%"} height={15} />
                <Skeleton width={"90%"} height={13} />
              </div>
            </div>
          </>
        ) : (
          <div className="dashboard-reminders">
            {reminders.length === 0 ? (
              <div className="not-found">{translations?.message?.noDataAvailable ? translations.message.noDataAvailable :"No data available."}</div>
            ) : (
              <>
                {reminders.map((reminder, index) => (
                  <Note
                  
                    key={index}
                    value={reminder.name}
                    date={formatDateStyle(reminder.remind_at)}
                    textDate={translations?.message?.inForceDate ? translations.message.inForceDate : "in Force Date"} 
                    title={reminder.reminder_doctype}
                    textTitle={"Health & Safety"}
                    title2={"Upcoming Amendment:"}
                    titleLink={`/dashboard/regulation/${encodeURIComponent(reminder.reminder_docname)}`}
                    para={
                      <DescriptionWithReadMore
                        description={reminder.description}
                      />
                    }
                    docName={reminder.reminder_docname}
                    url={url}
                    translations={translations}
                  />
                ))}

                <div className="note-link-container">
                  <Link
                    reloadDocument
                    to={"/dashboard/reminders"}
                    className="note-link"
                  >{translations?.message?.goToRemindersList ? translations.message.goToRemindersList : " Go To Reminders List"} 
                    <img src={arrowRight} alt="arrow" />
                  </Link>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{translations?.message?.fullDescription ? translations.message.fullDescription :"Full Description"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{fullDescription}</Modal.Body>
      </Modal>

      <SubModal
              open={subPopup}
              onClose={handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
              >
                <Box className="subpopup-container">
                    <p className='subpopup-text'>
                    {
                        messagePopup ? messagePopup : "your subscription has expired"
                    }
                    </p>
                    <a className='subpopup-link' href='/pricing'>Go To Pricing</a>
                </Box>
        </SubModal>
    </div>
  );
};

export default Reminders;
