import React, { useState, useEffect } from "react";
import "./UserProfile.css";
import ProfileInfo from "../ProfileInfo/ProfileInfo";
import Cookies from 'js-cookie';
import { SpinnerLoading } from "../../../../Components";
import 'react-phone-number-input/style.css';
import { toast } from "react-toastify";


import SubModal from '@mui/material/Modal';
import Box from '@mui/material/Box';


const UserProfile = ({ url, template, refresh, translations }) => {
  const templateProfileData = template?.data?.content?.profile || {};
  const userImageCookie = Cookies.get('user_image');
  const initialUserImage = templateProfileData.user_image || null;

  const [subPopup, setSubPopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState("")   

  const handleCloseSubPopup = () => {
      setSubPopup(false);
  };

  const [profileData, setProfileData] = useState({
    first_name: templateProfileData.first_name || '',
    last_name: templateProfileData.last_name || '',
    email: templateProfileData.name || '',
    user_image: initialUserImage
  });

  const [editedProfileData, setEditedProfileData] = useState({
    first_name: templateProfileData.first_name || '',
    last_name: templateProfileData.last_name || '',
    email: templateProfileData.name || ''
  });

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(
    initialUserImage ? `${url}${initialUserImage.filename || initialUserImage}` : null
  );
  const [prevProfileImageUrl, setPrevProfileImageUrl] = useState(initialUserImage ? `${url}${initialUserImage.filename}` : null);
  const [hasChanges, setHasChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (templateProfileData.user_image && !userImageCookie) {
      Cookies.set('user_image', JSON.stringify(templateProfileData.user_image));
    }
  }, [templateProfileData.user_image, userImageCookie]);

  

  useEffect(() => {
    const isChanged = profileData.first_name !== editedProfileData.first_name ||
      profileData.last_name !== editedProfileData.last_name ||
      profileData.email !== editedProfileData.email ||
      profilePhoto !== null;

    setHasChanges(isChanged);
  }, [editedProfileData, profilePhoto, profileData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    try {
      const apiKey = Cookies.get('api_key');
      const apiSecret = Cookies.get('api_secret');
      const updatedProfileData = { ...profileData, ...editedProfileData };

      if (profilePhoto) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const photoData = {
            filename: profilePhoto.name,
            data: reader.result.split(',')[1]
          };
          updatedProfileData.user_image = photoData;

          await updateProfile(apiKey, apiSecret, updatedProfileData, true);
        };
        reader.readAsDataURL(profilePhoto);
      } else {
        await updateProfile(apiKey, apiSecret, updatedProfileData, false);
      }
    } catch (error) {
      console.error("Error updating profile data:", error);
      alert(translations?.message?.errorUpdatingProfile ? translations.message.errorUpdatingProfile:'Error updating profile');
      setIsLoading(false); 
    }
  };

  const updateProfile = async (apiKey, apiSecret, updatedProfileData, isPhotoChanged) => {
    try {
      if (!isPhotoChanged) {
        delete updatedProfileData.user_image;
      }
      
      const response = await fetch(`${url}/api/method/erpnext.selling.doctype.customer.customer.employee`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
        body: JSON.stringify(updatedProfileData)
      });

      const result = await response.json();
      if (result.status_code === 200) {
        toast.success(translations?.message?.profileUpdatedSuccessfuly ? translations.message.profileUpdatedSuccessfuly:"Profile updated successfully.");

        // Update cookies and state if photo has changed
        if (isPhotoChanged) {
          const newUserImageUrl = `/files/${updatedProfileData.user_image.filename || ''}`;

          // Set the user_image cookie
          // Cookies.set('user_image', newUserImageUrl, { path: '/', sameSite: 'Lax' });
          Cookies.set('user_image', newUserImageUrl);
          // Trigger a manual update (optional: trigger a custom event or update a global state)
          window.dispatchEvent(new Event("storage")); // 

          // Update the profileImageUrl state to reflect the new image URL
          setProfileImageUrl(`${url}${newUserImageUrl}`);
      
          // Update the profileData to include the new user image details
          updatedProfileData.user_image = { filename: updatedProfileData.user_image.filename }; // Assuming user_image is an object

        }
        Cookies.set('full_name', result.result.full_name);
        window.dispatchEvent(new Event("storage")); 
        setProfileData(updatedProfileData);
      } else {
        toast.error(translations?.message?.errorUpdatingProfile ? translations.message.errorUpdatingProfile:"Error updating profile.");
      }

      if (response?.data?.status_code === 202) {
        setSubPopup(true);
      }
    } catch (error) {
      if (error?.response?.data?.status_code === 202) {
        setSubPopup(true);
        setMessagePopup(error?.response?.data?.message)
      }
      toast.error(translations?.message?.errorUpdatingProfile ? translations.message.errorUpdatingProfile:"Error updating profile.");
    } finally {
      setIsLoading(false); // End loading after updating profile
    }
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setProfilePhoto(null); 
      setProfileImageUrl(initialUserImage ? `${url}${initialUserImage.filename}` : null);
      return;
    }

    try {
      const imageUrl = URL.createObjectURL(file);
      setProfilePhoto(file);
      setProfileImageUrl(imageUrl);
      
    } catch (error) {
      console.error("Error creating object URL:", error);
    }
  };
  



  const handleCancel = () => {
    setEditedProfileData({
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      email: profileData.email,
    });
    setProfilePhoto(null);
    setProfileImageUrl(templateProfileData.user_image ? `${url}${templateProfileData.user_image}` : null);
    setHasChanges(false);
  };

  

  return (
    isLoading ? (
      <div className="profile-page-loading-container">
        <div className="profile-page-loading">
          <SpinnerLoading />
        </div> 
      </div>
    ) : (
      <div className="">
        <ProfileInfo
          name={`${profileData.first_name} ${profileData.last_name}`}
          email={profileData.email}
          ProfilePhoto={profileImageUrl}
          onPhotoChange={handlePhotoChange}
          photoName={translations?.message?.profilePhoto ? translations.message.profilePhoto : "Profile Photo"}
        />
        <div className="">
          <div className="-tab profile-form2">
            <div className="user-edit">
              <span className="tab-photo">
                {translations?.message?.userDetails ? translations.message.userDetails : "User Details"}
                </span>
            </div>
            <form className="row new-profile-form" >
              <div className="col-lg-4 top-label">
                <div className="form-group">
                  <label className="profile-label" htmlFor="tbFirstName">
                  {translations?.message?.firstName ? translations.message.firstName : "First Name"}
                  </label>
                  <input
                    type="text"
                    className="form-control profile-form-input"
                    placeholder={translations?.message?.firstName ? translations.message.firstName : "First Name"}
                    value={editedProfileData.first_name}
                    onChange={(e) => setEditedProfileData({ ...editedProfileData, first_name: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-lg-4 top-label">
                <div className="form-group">
                  <label className="profile-label" htmlFor="tbLastName">
                  {translations?.message?.lastName ? translations.message.lastName : "Last Name"}
                  </label>
                  <input
                    type="text"
                    className="form-control profile-form-input"
                    placeholder={translations?.message?.lastName ? translations.message.lastName : "Last Name"}
                    value={editedProfileData.last_name}
                    onChange={(e) => setEditedProfileData({ ...editedProfileData, last_name: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-lg-4 top-label">
                <div className="form-group">
                  <label className="profile-label" htmlFor= "tbEmail">
                  {translations?.message?.email ? translations.message.email : "Email"}
                  </label>
                  <input
                    type="email"
                    className="form-control profile-form-input"
                    placeholder={translations?.message?.email ? translations.message.email : "Email"}
                    value={editedProfileData.email}
                    onChange={(e) => setEditedProfileData({ ...editedProfileData, email: e.target.value })}
                  />
                </div>
              </div>
              <div className="button-container">
                {hasChanges && (
                  <>
                    <a className="btn-user-details" style={{ color: "#828282" }} onClick={handleCancel}>
                    {translations?.message?.cancel ? translations.message.cancel : "Cancel"}
                    </a>
                    <a onClick={handleSubmit} className="btn-user-details" style={{ paddingLeft: "30px", cursor: "pointer" }} disabled={isLoading}>
                    {translations?.message?.save ? translations.message.save : "Save"}
                    </a>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
        <SubModal
              open={subPopup}
              onClose={handleCloseSubPopup}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
              >
                <Box className="subpopup-container">
                    <p className='subpopup-text'>
                    {
                        messagePopup ? messagePopup : "your subscription has expired"
                    }
                    </p>
                    <a className='subpopup-link' href='/pricing'>Go To Pricing</a>
                </Box>
          </SubModal>
      </div>
    )    
  );
};

export default UserProfile;
