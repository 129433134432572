import React from 'react'
import "./Document.css"

const Document = ({ name, active, close }) => {
    return (
        <div className={`modal-document ${active ? 'active' : ''}`}>
            {name}
            {
                close && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                        <path d="M0.8 8L0 7.2L3.2 4L0 0.8L0.8 0L4 3.2L7.2 0L8 0.8L4.8 4L8 7.2L7.2 8L4 4.8L0.8 8Z" fill="#2DB0D8"/>
                    </svg>
                )
            }
        </div>
    )
}

export default Document
